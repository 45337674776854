import { useState } from "react";
import { useLoaderData } from "react-router-dom";
import { Question, Quiz as QuizInstance } from "./models/Quiz";
import '../Global.css';
import './Quiz.css';
import { checkAnswer } from "./Answer";
import { throwBadQuestionType } from "./TemplateToQuiz";
import { MathRangeQuestion } from "./MathRangeQuestion";
import { MultipleChoiceQuestionElement } from "./MultipleChoiceQuestionElement";
import { QuizResults } from "./QuizResults";

interface QuizState {
    questionIndex: number;
    answers: (string | number)[];
    currentQuestion: Question;
    incorrectAnswers: QuestionAnswer[];
}

// FIXME: Needs to be in a separate file
export interface QuestionAnswer {
    question: Question;
    answer: string;
}

export function Quiz() {
    const quiz = useLoaderData() as QuizInstance;
    const quizStartingState: QuizState = {
        questionIndex: 0,
        answers: [] as (string | number)[],
        currentQuestion: quiz.questions[0],
        incorrectAnswers: []
    };

    const [state, setState] = useState(quizStartingState);

    const handleQuestionAnswered = (answer: string) => {
        const wasCorrect = checkAnswer(answer, state.currentQuestion);

        const newIncorrectAnswers = wasCorrect ? state.incorrectAnswers : [...state.incorrectAnswers, { question: state.currentQuestion, answer: answer }];
        console.log("incorrect answers", newIncorrectAnswers);

        const nextQuestion = quiz.questions[state.questionIndex + 1];
        setState({
            ...state,
            questionIndex: state.questionIndex + 1,
            currentQuestion: nextQuestion,
            answers: [...state.answers, answer],
            incorrectAnswers: newIncorrectAnswers
        });
    }

    const renderQuizState = () => {
        switch(state.questionIndex < quiz.questions.length) {
            case true:
                return renderQuestion(state.currentQuestion, handleQuestionAnswered);
            case false:
                return <QuizResults quiz={quiz} incorrectAnswers={state.incorrectAnswers} />
        }
    }

    return (
        <div className="mainContainer">
            <div className="quizInfo">
                <span>
                    {quiz.title}
                </span>

                {quiz.questions.length > state.questionIndex &&
                    <span>
                        {state.questionIndex + 1} of {quiz.questions.length}
                    </span>
                }
            </div>

            {renderQuizState()}
        </div>
    )
}


function renderQuestion(currentQuestion: Question, handleQuestionAnswered: (answer: string) => void): import("react").ReactNode {
    switch(currentQuestion.type) {
        case "mathRange":
            return <MathRangeQuestion question={currentQuestion} onAnswer={handleQuestionAnswered} />
        case "multipleChoice":
            return <MultipleChoiceQuestionElement question={currentQuestion} onAnswer={handleQuestionAnswered} />
        default:
            return throwBadQuestionType(currentQuestion);
    }
}
