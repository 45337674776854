import '../Global.css';
import './MathRangeQuestion.css';
import { FormEvent, useState } from "react";
import { MathRangeInputQuestion } from "./models/Quiz";

interface QuestionProps {
    question: MathRangeInputQuestion;
    onAnswer: (answer: string) => void;
}

export function MathRangeQuestion({ question, onAnswer } : QuestionProps) {
    const [value, setValue] = useState("")
    const handleSubmit = (evt: FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        onAnswer(value);
        setValue("");
    }

    return (<form onSubmit={handleSubmit}>
        <h1 className="question">{question.text}</h1>

        <input dir="rtl" type="number" autoFocus value={value} onChange={e => setValue(e.target.value)}/>
        <button type="submit">Submit</button>
    </form>)
}
