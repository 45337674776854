import { useState } from "react";
import './MultiChoice.css';

/**
 * Renders a multiple choice question using a series of divs that can be clicked on and light up when selected.
 * 
 * @param params list of options to choose from
 */
export interface MultiChoiceProps {
    options: string[];
    selected?: number;
    clickHandler: (index: number) => void;
}

export function MultiChoice({ options, selected, clickHandler }: MultiChoiceProps) {
    return (
        <div className="multipleChoice">
            {options.map((option, index) => {
                return (
                    <div key={index} className={"option "+ (selected === index ? "selected" : "")} onClick={() => clickHandler(index)}>
                        {option}
                    </div>
                )
            })}
        </div>
    )
}