import '../Global.css'
import { FormEvent, useState } from "react";
import { MultipleChoiceQuestion } from "./models/Quiz";
import { MultiChoice } from './MultiChoice';

interface QuestionProps {
    question: MultipleChoiceQuestion;
    onAnswer: (answer: string) => void;
}

export function MultipleChoiceQuestionElement({ question, onAnswer } : QuestionProps) {
    const [selected, setSelected] = useState<number | undefined>(undefined);
    const handleSubmit = (evt: FormEvent<HTMLFormElement>) => {
        evt.preventDefault();

        if(selected === undefined) {
            alert("Please select an answer");
            return;
        } else {
            onAnswer(selected.toString());
            setSelected(undefined);
        }
    }

    return(<form onSubmit={handleSubmit}>
        <h1 className="question">{question.text}</h1>

        <MultiChoice options={question.answers} clickHandler={setSelected} selected={selected}></MultiChoice>

        <button type="submit">Submit</button>
    </form>
    );
}
