import { useLoaderData } from "react-router-dom";
import { QuizTemplate } from "./models/Template";

export function QuizList() {
    const quizzes = useLoaderData() as QuizTemplate[];

    return (
        <ul>
            {quizzes.map(quiz => (
                <li key={quiz.id}>
                    <h2><a href={`/quiz/${quiz.id}`}>{quiz.title}</a></h2>
                    <p>{quiz.description}</p>
                </li>
            ))}
        </ul>
    )
}