import './App.css';
import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  LoaderFunctionArgs,
} from "react-router-dom";
import "./index.css";
import { QuizList } from './quiz/QuizList';
import { Quiz } from './quiz/Quiz';
import { convertTemplateToQuiz } from './quiz/TemplateToQuiz';

// FIXME: Dis a damn mess bruh
export async function loadQuiz(args: LoaderFunctionArgs) {
  const quizzesResponse = await fetch(`/quiz.json`);
  const quizzes = await quizzesResponse.json();
  const quizId = parseInt(args.params.quizId as string, 10);

  const quiz = convertTemplateToQuiz(quizzes.quizzes[quizId]);
  console.log(quiz);
  return quiz;
}

export async function quizLoader() {
  const quizzesResponse = await fetch(`/quiz.json`);
  const quizTemplateContainer = await quizzesResponse.json();
  return quizTemplateContainer.quizzes;
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <QuizList></QuizList>,
    loader: quizLoader
  },
  {
    path: "/quiz/:quizId",
    element: <Quiz />,
    loader: loadQuiz
  }
]);

function App() {
  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}

export default App;
