import { Question } from "./models/Quiz";
import { throwBadQuestionType } from "./TemplateToQuiz";

export function checkAnswer(answer: string, question: Question): boolean {
    switch(question.type) {
        case "mathRange":
            return parseInt(answer, 10) === question.answer;
        case "multipleChoice":
            return parseInt(answer, 10) === question.answerIndex;
        default:
            return throwBadQuestionType(question);
    }
}