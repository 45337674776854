export function selectRandomFromArray<Type>(array: Type[]): Type {
    return array[Math.floor(Math.random() * array.length)];
}

export type MathOperators = "+" | "-" | "*" | "/";

/**
 * Use Fisher-Yates shuffle to shuffle an array
 */
export function shuffleArray<Type>(array: Type[]): Type[] {
    let currentIndex = array.length;
    let temporaryValue: Type;
    let randomIndex: number;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
}

export function classNames(classes: { [key: string]: boolean }): string {
  return Object.entries(classes)
    .filter(([_, value]) => value)
    .map(([key, _]) => key)
    .join(' ');
}