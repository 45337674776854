import { MathRangeInputQuestion, MultipleChoiceQuestion, Quiz } from "./models/Quiz";
import { QuestionAnswer } from "./Quiz";
import { throwBadQuestionType } from "./TemplateToQuiz";
import './QuizResults.css';
import { classNames } from "../utils";

export interface QuizResultProps {
    quiz: Quiz;
    incorrectAnswers: QuestionAnswer[];
}

export function QuizResults( { quiz, incorrectAnswers }: QuizResultProps) {
    // Percentage of questions answered correctly, rounded to the nearest whole number
    const percentage = Math.round((quiz.questions.length - incorrectAnswers.length) / quiz.questions.length * 100);
    return (
        <div>
            <h1>Quiz Results</h1>
            <div>
                <span>Score:</span><span>{percentage}%</span>
            </div>

            {incorrectAnswers.length > 0 && <IncorrectAnswers incorrectAnswers={incorrectAnswers} /> }
        </div>
    )
}

function IncorrectAnswers({ incorrectAnswers } : { incorrectAnswers: QuestionAnswer[] }) {
    return (
        <div>
            <h2>Incorrect Answers</h2>
            {incorrectAnswers.map((incorrectAnswer) => {
                switch(incorrectAnswer.question.type) {
                    case "multipleChoice":
                        return <IncorrectMultipleChoiceAnswer incorrectAnswer={incorrectAnswer} />
                    case "mathRange":
                        return <IncorrectMathRangeAnswer incorrectAnswer={incorrectAnswer} />
                    default:
                        return throwBadQuestionType(incorrectAnswer.question);
                }
            })}
        </div>
    )
}

function IncorrectMathRangeAnswer({ incorrectAnswer }: { incorrectAnswer: QuestionAnswer }) {
    const question = incorrectAnswer.question as MathRangeInputQuestion;
    return (
        <div className="incorrectAnswerContainer">
            <h3>{question.text}</h3>
            <div>
                <span>Your answer: </span><span>{incorrectAnswer.answer}</span>
            </div>

            <div>
                <span>Correct answer: </span><span>{question.answer}</span>
            </div>
        </div>
    )
}

function IncorrectMultipleChoiceAnswer({ incorrectAnswer }: { incorrectAnswer: QuestionAnswer }) {
    const question = incorrectAnswer.question as MultipleChoiceQuestion;
    console.log(incorrectAnswer);
    return (
        <div className="incorrectAnswerContainer">
            <h3>{question.text}</h3>
            <div>
                {question.answers.map((option, index) => {
                    const classes = classNames({
                        "correct": question.answerIndex === index,
                        "incorrect": index === parseInt(incorrectAnswer.answer, 10)
                    });

                    return (
                        <div className={classes}>
                            {index}: {option}
                        </div>
                    )
                })
                }
            </div>
        </div>
    )
}