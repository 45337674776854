import * as math from "mathjs";
import { MathOperators, selectRandomFromArray, shuffleArray } from "../utils";
import { MathRangeInputQuestion, MultipleChoiceQuestion, Question, Quiz } from "./models/Quiz";
import { BucketQuestionTemplate, MathRangeQuestionTemplate, MultipleChoiceTextQuestion, QuestionTemplate, QuizTemplate } from "./models/Template";

/**
 * Iterate all the terms and operators and then convert it to a text equation
 */
function computeAnswer(text: string): number {
    return math.evaluate(text);
}

function convertMathRange(questionParams: MathRangeQuestionTemplate): MathRangeInputQuestion {
    const terms = questionParams.terms.map(term => Math.floor(Math.random() * (term.max - term.min + 1)) + term.min);
    const operators: MathOperators[] = [];

    for(let i = 0; i < terms.length - 1; i++) {
        operators.push(selectRandomFromArray(questionParams.operatorRange));
    }

    let text = '' + terms[0];
    for(let i = 1; i < terms.length; i++) {
        text = `${text} ${operators[i-1]} ${terms[i]}`;
    }

    return {
        terms,
        operators,
        answer: computeAnswer(text),
        text,
        type: "mathRange"
    }
}

export function throwBadQuestionType(question: never): never {
    throw new Error(`Unknown question type`);
}

function convertTemplateQuestionToQuestion(templateQuestion: QuestionTemplate): Question[] {
    const response = [];

    for(let i = 0; i < (templateQuestion.repeat || 1); i++) {
        switch(templateQuestion.type) {
            case "mathRange":
                response.push(convertMathRange(templateQuestion));
                break;
            case "multipleChoiceTextQuestion":
                response.push(convertTextQuestion(templateQuestion));
                break;
            case "bucket":
                response.push(...convertBucketQuestion(templateQuestion));
                break;
            default:
                throwBadQuestionType(templateQuestion);
        }
    }

    if(response.length === 0) {
        throw new Error(`No questions were generated from the template with the id ${templateQuestion.id}`);
    } else {
        return response;
    }
}

export function convertTemplateToQuiz(template: QuizTemplate): Quiz {
    const questions = template.questions.flatMap(convertTemplateQuestionToQuestion);
    if(template.shuffle) {
        // FIXME: Should this be immutable?
        shuffleArray(questions);
    }

    return {
        id: template.id,
        title: template.title,
        description: template.description,
        questions
    }
}

/**
 * Generates all possible multiple choice questions from a bucket question
 */
function convertBucketQuestion(templateQuestion: BucketQuestionTemplate): MultipleChoiceQuestion[] {
    const possibleAnswers = Object.keys(templateQuestion.buckets);

    const questions: MultipleChoiceQuestion[] = possibleAnswers.flatMap(answer => {
        const bucketContents = templateQuestion.buckets[answer];

        return bucketContents.map(bucketContent => {
            const answerIndex = possibleAnswers.indexOf(answer);

            return {
                type: "multipleChoice",
                text: `${templateQuestion.textBefore}${bucketContent}${templateQuestion.textAfter}`,
                answerIndex,
                answers: possibleAnswers
            }
        });
    });

    shuffleArray(questions);
    if(templateQuestion.maxNumberOfAppearances === undefined) {
        return questions;
    } else {
        return questions.slice(0, templateQuestion.maxNumberOfAppearances);
    }
}

function convertTextQuestion(templateQuestion: MultipleChoiceTextQuestion): MultipleChoiceQuestion {
    const answers = [templateQuestion.answer, ...templateQuestion.incorrectAnswers];
    const answerIndex = answers.indexOf(templateQuestion.answer);

    return {
        type: "multipleChoice",
        text: templateQuestion.text,
        answerIndex,
        answers
    }
}

